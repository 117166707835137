import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { ENV } from 'config';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import addressReducer from 'redux/Slices/address';
import appointmentReducer from 'redux/Slices/appointment';
import insuranceReducer from 'redux/Slices/insurance';

import practiceReducer from '../Slices/practice';
import regionReducer from '../Slices/regions';
import remoteConfigReducer from '../Slices/remoteConfig';

export const reducers = combineReducers({
  regionReducer,
  practiceReducer,
  appointmentReducer,
  insuranceReducer,
  addressReducer,
  remoteConfigReducer,
});

const sentryReduxEnhancer = createReduxEnhancer({});

export const store = configureStore({
  reducer: reducers,
  devTools: ENV !== 'PROD',
  enhancers: (defaultEnhancers) => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
export const useAppDispatch: () => TAppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;
