import aviLogo from 'assets/images/avi_logo_new.svg';
import { APP_STORE_LINK_DE, APP_STORE_LINK_EN, GOOGLE_PLAY_LINK_DE, GOOGLE_PLAY_LINK_EN } from 'config';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { isAndroid, isChromeIOS, isIOS } from 'utils';

import { IntlContext } from 'hooks/useIntl';
import { IBeforeInstallPromptEvent } from 'hooks/usePromptToInstall';

import './_styles.scss';

type TBannerProps = {
  deferredPrompt: IBeforeInstallPromptEvent | null;
  onDismiss: () => void;
};

const DownloadBanner = ({ deferredPrompt, onDismiss }: TBannerProps) => {
  const { language } = useContext(IntlContext);
  const { formatMessage } = useIntl();

  const message = formatMessage({
    id: 'Components.DownloadBanner.message',
    description: 'Message for DownloadBanner component',
    defaultMessage: 'Verwalten Sie Arzttermine noch bequemer mit der avi App.',
  });

  const dismissLabel = formatMessage({
    id: 'Components.DownloadBanner.dismissLabel',
    description: 'Dismiss label for DownloadBanner component',
    defaultMessage: 'schliessen',
  });

  const openLabel = formatMessage({
    id: 'Components.DownloadBanner.openLabel',
    description: 'Open label for DownloadBanner component',
    defaultMessage: 'zum PlayStore',
  });

  const openButton = useMemo(() => {
    if (deferredPrompt) {
      return (
        <button className='banner-button' onClick={deferredPrompt?.prompt}>
          {openLabel}
        </button>
      );
    } else if (isAndroid()) {
      return (
        <a
          className='banner-button'
          href={language === 'en' ? GOOGLE_PLAY_LINK_EN : GOOGLE_PLAY_LINK_DE}
          target='_blank'
          rel='noreferrer'>
          {openLabel}
        </a>
      );
    } else if (isIOS() && isChromeIOS()) {
      return (
        <a
          className='banner-button'
          href={language === 'en' ? APP_STORE_LINK_EN : APP_STORE_LINK_DE}
          target='_blank'
          rel='noreferrer'>
          {openLabel}
        </a>
      );
    }
  }, [deferredPrompt, openLabel, language]);

  return (
    <div className='download-banner'>
      <div className='logo-and-text'>
        <img className='banner-logo' src={aviLogo} alt='avi medical logo' />
        <div>{message}</div>
      </div>

      <div className='download-buttons'>
        <button className='banner-button' onClick={onDismiss}>
          {dismissLabel}
        </button>
        {openButton}
      </div>
    </div>
  );
};

export default DownloadBanner;
