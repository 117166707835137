const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';
const MAIN_PAGE_URL = 'https://www.avimedical.com';

const GOOGLE_PLAY_LINK_EN = 'https://play.google.com/store/apps/details?id=com.avimedical.avi';
const GOOGLE_PLAY_LINK_DE = 'https://play.google.com/store/apps/details?id=com.avimedical.avi&hl=de';
const APP_STORE_LINK_EN = 'https://apps.apple.com/us/app/avi-medical/id1526797754';
const APP_STORE_LINK_DE = 'https://apps.apple.com/de/app/avi-medical/id1526797754';

type TConfig = {
  apiBaseUrl: string;
  apiBaseUrlV2: string;
  webApiBaseUrl: string;
  staticResourcesBaseUrl: string;
  userLikeDE: string;
  userLikeEN: string;
  googleMaps: {
    apiKey: string;
  };
  googleCredentials: {
    clientId: string;
    apiKey: string;
  };
  hcaptcha: {
    sitekey: string;
  };
  countly: {
    appKey: string;
    instanceUrl: string;
  };
  ENV: 'DEV' | 'STG' | 'TEST' | 'PROD';
  releaseVersion: string;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    config: TConfig;
  }
}

const {
  apiBaseUrl,
  apiBaseUrlV2,
  webApiBaseUrl,
  staticResourcesBaseUrl,
  googleCredentials,
  googleMaps,
  hcaptcha,
  ENV,
  userLikeDE,
  userLikeEN,
  countly,
  releaseVersion,
} = window.config || {
  apiBaseUrl: process.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
  apiBaseUrlV2: process.env.API_BASE_URL_V2 || process.env.REACT_APP_API_BASE_URL_V2,
  webApiBaseUrl: process.env.REACT_APP_WEB_API_BASE_URL,
  staticResourcesBaseUrl: process.env.REACT_APP_STATIC_RESOURCES_BASE_URL,
  userLikeDE: process.env.REACT_APP_USERLIKE_DE_ID,
  userLikeEN: process.env.REACT_APP_USERLIKE_EN_ID,
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
  googleCredentials: {
    clientId: process.env.REACT_APP_G_CLIENT_ID,
    apiKey: process.env.REACT_APP_G_API_KEY,
  },
  hcaptcha: {
    sitekey: process.env.REACT_APP_HCAPTCHA_SITEKEY_STG,
  },
  countly: {
    appKey: process.env.REACT_APP_COUNTLY_APP_KEY,
    instanceUrl: process.env.REACT_APP_COUNTLY_APP_URL,
  },
  releaseVersion: process.env.REACT_APP_VERSION,
  ENV: process.env.REACT_APP_ENV || 'DEV',
};

export {
  apiBaseUrl,
  apiBaseUrlV2,
  webApiBaseUrl,
  staticResourcesBaseUrl,
  DISCOVERY_DOCS,
  SCOPES,
  MAIN_PAGE_URL,
  googleCredentials,
  googleMaps,
  hcaptcha,
  GOOGLE_PLAY_LINK_EN,
  GOOGLE_PLAY_LINK_DE,
  APP_STORE_LINK_EN,
  APP_STORE_LINK_DE,
  ENV,
  userLikeDE,
  userLikeEN,
  countly,
  releaseVersion,
};
