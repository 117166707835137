export const parseRemoteConfigMap = (input: Record<string, string | boolean | number | null>) => {
  const output: Record<string, boolean | number | string> = {};

  for (const [key, value] of Object.entries(input)) {
    if (value === null || (typeof value === 'string' && value.trim() === '')) continue;

    if (value === 'true' || value === true) {
      output[key] = true;
    } else if (value === 'false' || value === false) {
      output[key] = false;
    } else if (!isNaN(Number(value))) {
      output[key] = Number(value);
    } else {
      // keep other strings, and numbers
      output[key] = value;
    }
  }

  return output;
};
