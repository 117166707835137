import useAppContext, { Step } from 'AppContext';
import { DEFAULT_HAS_RECENT_APPOINTMENTS, DEFAULT_IS_FIRST_APPOINTMENT, DEFAULT_IS_HZV } from 'constants/appointment';
import { Suspense, useEffect } from 'react';
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import Sidebar from 'components/Sidebar/Sidebar';

import { AppRoutes } from 'utils/routeUtils';

import useRemoteConfig from 'hooks/useRemoteConfig';

import './styles.scss';

import Navbar from '../Navbar/Navbar';

// this component is rendered as a route
// before the routes are available, the app state gets bootstrapped
// so we can expect the app state to be correctly set up here
const BookingFlowContainer = () => {
  const { context, setCurrentStep } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    values: { ask_for_patient_return_type },
  } = useRemoteConfig();

  const { setContext } = useAppContext();
  const { isFirstAppointment, rfeList } = context.apptData;
  useEffect(() => {
    // If isFirstAppointment is not set, go to is-first-appointment screen
    if (isFirstAppointment === null && ask_for_patient_return_type) {
      navigate(AppRoutes.IsFirstAppointment());
      setCurrentStep(Step.IS_FIRST_APPOINTMENT);
      return;
    } else if (!ask_for_patient_return_type) {
      setContext((prev) => ({
        ...prev,
        apptData: {
          ...prev.apptData,
          isFirstAppointment: DEFAULT_IS_FIRST_APPOINTMENT,
          isHzvPatient: DEFAULT_IS_HZV,
          patientSelectedHadARecentAppointment: DEFAULT_HAS_RECENT_APPOINTMENTS,
        },
      }));
    }

    // If :
    // - No rfe are selected
    // - OR We are on the rfe-selection screen
    // - OR We are on the acute_symptom screen
    // => Go to the rfe-selection screen
    if (
      rfeList.length === 0 ||
      [AppRoutes.SelectRfe(), AppRoutes.AcuteSymptom()].some((route) => location.pathname.startsWith(route))
    ) {
      navigate(AppRoutes.SelectRfe());
      setCurrentStep(Step.RFE);
      return;
    }

    // If we are on the note screen
    // Stay on it
    if (matchPath(AppRoutes.AppointmentNotes(), location.pathname)) {
      return;
    }

    setCurrentStep(Step.APPOINTMENT);

    // Otherwise redirect to the city-selection screen or the appointment screen
    if (localStorage.getItem('preferred-region')) {
      navigate(AppRoutes.Appointment());
    } else {
      navigate(AppRoutes.Regions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ask_for_patient_return_type]);

  return (
    <div className='booking-flow-container'>
      <Navbar />
      <div className='booking-flow-content'>
        <Sidebar />
        <Suspense fallback={<PageLoader />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default BookingFlowContainer;
