import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { webApi } from 'services/api';

import { TRemoteConfig } from './types';

import { parseRemoteConfigMap } from './utils';
import Sentry from 'utils/sentry';

export const fetchRemoteConfig = createAsyncThunk('fetchRemoteConfig', async (): Promise<TRemoteConfig | null> => {
  try {
    const resp = await webApi.get<TRemoteConfig>(`/api/v1/remote-config`);
    return parseRemoteConfigMap(resp.data);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
});

type TRemoteConfigState = {
  values: TRemoteConfig | null;
  isInitialized: boolean;
  isFetching: boolean;
  error: boolean;
};

const initialState: TRemoteConfigState = {
  values: null,
  isInitialized: false,
  isFetching: false,
  error: false,
};

const remoteConfigSlice = createSlice({
  name: 'remoteConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRemoteConfig.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchRemoteConfig.fulfilled, (state, action) => {
      // if we are unable to (re)fetch the remote config, we will keep the current values
      if (action.payload !== null) {
        state.values = action.payload;
      }
      state.isFetching = false;
      state.isInitialized = true;
      state.error = false;
    });
    builder.addCase(fetchRemoteConfig.rejected, (state) => {
      state.isFetching = false;
      state.error = true;
    });
  },
});
export default remoteConfigSlice.reducer;
