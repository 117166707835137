import { createRoot } from 'react-dom/client';

import { initializeSentry } from 'utils/sentry';

import App from './App';

initializeSentry();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// Cleanup : Remove any serviceWorker previously installed
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch(() => {
      console.log('Issue unregistering service worker');
    });
}
