import { fetchRemoteConfig } from 'redux/Slices/remoteConfig';
import { useAppDispatch, useAppSelector } from 'redux/Store/store';

const useRemoteConfig = () => {
  const remoteConfig = useAppSelector((state) => state.remoteConfigReducer);
  const dispatch = useAppDispatch();

  const bootstrap = () => {
    dispatch(fetchRemoteConfig());
  };

  return {
    bootstrap,
    isBootstrapped: remoteConfig.isInitialized,
    values: remoteConfig.values || {},
  };
};

export default useRemoteConfig;
