import { AxiosRequestConfig } from 'axios';
import api, { webApi } from 'services/api';

import { AnamnesisStatus } from 'interfaces/Anamnesis';

export type TAnamnesis = TUpdateAnamnesisDTO & {
  id: string;
  template: any;
  answers: unknown;
};

type TUpdateAnamnesisDTO = {
  status: AnamnesisStatus;
  answers: unknown;
  displayPageIndex: number;
  lastViewedPageIndex: number;
  totalPages: number;
};

// used to update anamnesis with mobile app token
export const updateAnamnesis = async (id: string, token: string, payload: TUpdateAnamnesisDTO) => {
  try {
    const config: AxiosRequestConfig = {};
    config.headers = { Authorization: `Bearer ${token}` };

    return await api.put(`/anamnesis/${id}`, payload, config);
  } catch (err) {
    throw err;
  }
};

// used to fetch anamnesis with mobile app token
export const getAnamnesis = async (id: string, token: string) => {
  try {
    const config: AxiosRequestConfig = {};
    config.headers = { Authorization: `Bearer ${token}` };

    return await api.get<TAnamnesis>(`/anamnesis/${id}`, config);
  } catch (err) {
    throw err;
  }
};

// used to create mandatory anamnesis for the web booking flow
export const createMandatoryAnamnesis = async ({
  uniqueAppId,
  captchaToken,
}: {
  uniqueAppId: string;
  captchaToken: string;
}): Promise<TAnamnesis> => {
  try {
    const resp = await webApi.post<{ anamnesis: TAnamnesis }>(`/api/v1/anamnesis-evaluation`, {
      uniqueAppId,
      captchaToken,
    });
    return resp.data.anamnesis;
  } catch (err) {
    throw err;
  }
};

// used to update mandatory anamnesis without mobile app token (web booking flow)
export const updateAnamnesisBookingFlow = async (id: string, payload: TUpdateAnamnesisDTO): Promise<void> => {
  const strippedPayload: TUpdateAnamnesisDTO = {
    status: payload.status,
    answers: payload.answers,
    displayPageIndex: payload.displayPageIndex,
    lastViewedPageIndex: payload.lastViewedPageIndex,
    totalPages: payload.totalPages,
  };

  try {
    const resp = await webApi.put(`/api/v1/anamnesis/${id}`, strippedPayload);
    return resp.data;
  } catch (err) {
    throw err;
  }
};
