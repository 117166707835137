import useAppContext, { Step } from 'AppContext';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { BookingFlowConfig } from '../../utils/routeUtils';
import { hasAcuteRFESelected } from 'utils/rfeUtils';

import useRemoteConfig from 'hooks/useRemoteConfig';

import './_styles.scss';

const className = (step: Step, currentStep: Step) => {
  if (currentStep === step) return 'active';
  if (currentStep < step) return '';
  if (currentStep > step) return 'done';
};

type TCreateLinkProps = {
  step: Step;
  currentStep: Step;
  label: string;
  skipped?: boolean;
};

// we need to refactor and fix our current navigation to enable it
const IS_ANAMNESIS_HIDDEN = true;

const StepLink = ({ step, currentStep, label, skipped = false }: TCreateLinkProps) => (
  <li className={!skipped ? className(step, currentStep) : ''}>
    <Link to={BookingFlowConfig.getRoute(step)}>
      <p className={!skipped && currentStep >= step ? 'link' : ''}>{label}</p>
    </Link>
  </li>
);
const Sidebar = () => {
  const { formatMessage } = useIntl();
  const {
    values: { ask_for_patient_return_type, mandatory_anamnesis_enabled },
  } = useRemoteConfig();

  // context
  const { context: appCtx } = useAppContext();

  const IsFirstAppointmentLabel = formatMessage({
    id: 'Components.Sidebar.isFirstAppointmentLabel',
    description: 'Is First Appointment label',
    defaultMessage: 'Willkommen',
  });

  const RFELabel = formatMessage({
    id: 'Components.Sidebar.RFELabel',
    description: 'RFE label',
    defaultMessage: 'Besuchsgrund',
  });

  const notesLabel = formatMessage({
    id: 'Components.Sidebar.notesLabel',
    description: 'Notes label',
    defaultMessage: 'Notiz',
  });

  const anamnesisLabel = formatMessage({
    id: 'Components.Sidebar.anamnesisLabel',
    description: 'Anamnesis label',
    defaultMessage: 'Vorerkrankungen',
  });

  const appointmentLabel = formatMessage({
    id: 'Components.Sidebar.appointmentLabel',
    description: 'Appointment label',
    defaultMessage: 'Ort und Zeit',
  });

  const userDataLabel = formatMessage({
    id: 'Components.Sidebar.userDataLabel',
    description: 'User data label',
    defaultMessage: 'Persönliche Daten',
  });

  const mobileConfirmationLabel = formatMessage({
    id: 'Components.Sidebar.mobileConfirmationLabel',
    description: 'Mobile confirmation label',
    defaultMessage: 'Authentifizierung',
  });

  return (
    <div className='pwa-sidebar'>
      <ul className={'steps active'}>
        {ask_for_patient_return_type && (
          <StepLink step={Step.IS_FIRST_APPOINTMENT} currentStep={appCtx.currentStep} label={IsFirstAppointmentLabel} />
        )}
        <StepLink step={Step.RFE} currentStep={appCtx.currentStep} label={RFELabel} />
        <StepLink
          step={Step.NOTE}
          currentStep={appCtx.currentStep}
          label={notesLabel}
          //TODO: add feature flag for new booking flow (skip notes only on new booking flow)
          skipped={hasAcuteRFESelected(appCtx.apptData.rfeList)}
        />
        {mandatory_anamnesis_enabled && !IS_ANAMNESIS_HIDDEN && (
          <StepLink step={Step.ANAMNESIS} currentStep={appCtx.currentStep} label={anamnesisLabel} />
        )}
        <StepLink step={Step.APPOINTMENT} currentStep={appCtx.currentStep} label={appointmentLabel} />
        <StepLink step={Step.USERDATA} currentStep={appCtx.currentStep} label={userDataLabel} />
        <StepLink step={Step.AUTHENTICATION} currentStep={appCtx.currentStep} label={mobileConfirmationLabel} />
      </ul>
    </div>
  );
};

export default Sidebar;
