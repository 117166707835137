import { chunk } from 'lodash';

import { iconSourceMap } from 'pages/Anamnesis/components/custom-multi-select/utils/get-input-icon';

const DebugPage = () => {
  const all = Object.entries(iconSourceMap);
  const chunked = chunk(all, 20);

  return (
    <div style={{ display: 'flex', padding: '32px' }}>
      {chunked.map((chunk, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '400px' }}>
          {chunk.map(([key, value]) => {
            return (
              <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ width: '250px' }}>{key}</p>
                <img src={value as string} alt='' />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default DebugPage;
