import { withSentryReactRouterV6Routing } from '@sentry/react';
import useAppContext, { AppContextProvider } from 'AppContext';
import { ENV } from 'config';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import DebugPage from 'pages/Debug/DebugPage';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { fetchRemoteConfig } from 'redux/Slices/remoteConfig';
import { v4 as uuidv4 } from 'uuid';

import AppWrapper from 'components/AppWrapper';
import BookingFlowContainer from 'components/BookingFlowContainer';
import DownloadBanner from 'components/DownloadBanner/DownloadBanner';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import PageLoader from 'components/PageLoader';

import InsuranceSelectionData from './pages/Booking/InsuranceSelection/InsuranceSelection.data';

import { AppRoutes } from './utils/routeUtils';
import { isAndroid, isChromeIOS, isIOS, loadContextFromSessionStorage } from 'utils';

import useCookieBot from './hooks/useCookieBot';
import { IntlProvider } from 'hooks/useIntl';
import useMobileResolution from 'hooks/useMobileResolution';
import { usePromptToInstall } from 'hooks/usePromptToInstall';

import Countly from './modules/countly';
import { store } from './redux/Store/store';
import './scss/main.scss';
import { TOtpTypes } from './services/otpTypes';

// Booking Pages
const IsFirstAppointmentPage = lazy(() => import('pages/Booking/IsFirstAppointment'));
const RfeSelectionPage = lazy(() => import('pages/Booking/RFESelection/RFESelection.data'));
const RfeConfirmationPage = lazy(() => import('pages/Booking/RFEConfirmation'));
const SymptomPage = lazy(() => import('pages/Booking/Symptom/Symptom.data'));
const SymptomFollowUpPage = lazy(() => import('pages/Booking/SymptomFollowUp/SymptomFollowUp.data'));
const AcuteAppointmentWarningPage = lazy(
  () => import('pages/Booking/AcuteAppointmentWarning/AcuteAppointmentWarning.data')
);
const OutOfReachPage = lazy(() => import('pages/Booking/OutOfReach/OutOfReach.data'));
const NotesPage = lazy(() => import('pages/Booking/Notes/Notes.data'));
const RegionsPage = lazy(() => import('pages/Booking/Region'));
const AppointmentPage = lazy(() => import('pages/Booking/AppointmentCreate'));
const AppointmentConfirmationPage = lazy(() => import('pages/Booking/AppointmentConfirmation'));
const UserDataPage = lazy(() => import('pages/Booking/UserData'));
const TwoFaPage = lazy(() => import('pages/Booking/2FA/2FA'));
const TwoFaConfirmationPage = lazy(() => import('pages/Booking/2FA/2FAConfirm'));
const AddOnServicesPage = lazy(() => import('pages/Booking/AddOnServices/AddOnServices.data'));
const AnamnesisMandatoryPage = lazy(() => import('pages/Booking/AnamnesisMandatory/AnamnesisMandatory.data'));

// Consent Pages
const ConsentStart = lazy(() => import('pages/Consent/pages/Start'));

// Standalone Pages
const AddToCalendarPage = lazy(() => import('pages/AddToCalendar'));
const Cancellation = lazy(() => import('pages/Cancellation'));
const AccountVerification = lazy(() => import('pages/AccountVerification'));
const ViewMessage = lazy(() => import('pages/ViewMessage'));
const AnamnesisLanding = lazy(() => import('pages/AnamnesisLanding'));
const Anamnesis = lazy(() => import('pages/Anamnesis'));
const VideoCall = lazy(() => import('pages/VideoCall'));
const EmailVerificationLandingPage = lazy(() => import('pages/EmailVerificationLandingPage'));

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const SecretMobileRoute = ({ path, element }: { path: string; element: React.ReactElement }) => {
  const { context } = useAppContext();

  const isMobile = useMobileResolution();

  if (isMobile && context.apptData.rfeList.length > 0) {
    return (
      <SentryRoutes>
        <Route path={path} element={element} />
      </SentryRoutes>
    );
  } else if (!isMobile && context.practice.id) {
    return <Navigate to={AppRoutes.SelectRfe()} />;
  }

  return <Navigate to='/' />;
};

const uniqueAppId = loadContextFromSessionStorage()?.apptData?.uniqueAppId || uuidv4();

function App() {
  const [deferredPrompt] = usePromptToInstall();
  const [bannerVisible, setBannerVisible] = useState(false);
  const { consents } = useCookieBot();

  useEffect(() => {
    Countly.Init();
    // We need to ensure that the user selected the "statistics" consent before we can do anything WRT any tracking/tracing
    if (consents?.statistics) {
      Countly.giveConsent('activity');
      Countly.AutomaticPerfMonitoring();
      Countly.SessionStart();
      return Countly.SessionEnd();
    }
  }, [consents]);

  useEffect(() => {
    if (deferredPrompt || (isIOS() && isChromeIOS()) || isAndroid()) {
      const lastViewed = localStorage.getItem('bannerViewed');

      if (lastViewed) {
        if (!dayjs(lastViewed).isSame(dayjs(), 'day')) {
          setBannerVisible(true);
        }
      } else {
        setBannerVisible(true);
      }
    }
  }, [deferredPrompt]);

  const handleDismiss = () => {
    localStorage.setItem('bannerViewed', dayjs().toISOString());
    setBannerVisible(false);
  };

  // we cannot easily access and subscribe to store values here
  const [isRemoteConfigInitialized, setIsRemoteConfigInitialized] = useState(false);

  useEffect(() => {
    // fetch remote config immediately on mount
    store.dispatch(fetchRemoteConfig()).then(() => {
      setIsRemoteConfigInitialized(true);
    });

    // refetch remote config every 5 minutes
    const interval = setInterval(
      () => {
        store.dispatch(fetchRemoteConfig());
      },
      1000 * 60 * 5
    );

    return () => clearInterval(interval);
  }, []);

  if (!isRemoteConfigInitialized) return <PageLoader />;

  return (
    <HelmetProvider>
      <IntlProvider>
        <GlobalErrorBoundary>
          <Provider store={store}>
            <AppContextProvider uniqueAppId={uniqueAppId} bannerVisible={bannerVisible}>
              <BrowserRouter>
                <AppWrapper>
                  {bannerVisible && <DownloadBanner deferredPrompt={deferredPrompt} onDismiss={handleDismiss} />}
                  {/*Catch all Suspense fallback*/}
                  <Suspense fallback={<PageLoader />}>
                    <SentryRoutes>
                      <Route path={'/booking'} element={<BookingFlowContainer />}>
                        <Route path={AppRoutes.IsFirstAppointment()} element={<IsFirstAppointmentPage />} />

                        <Route path={AppRoutes.RfeDetails(':code')} element={<RfeSelectionPage />} />
                        <Route path={AppRoutes.SelectRfe()} element={<RfeSelectionPage />} />
                        <Route path={AppRoutes.AcuteSymptom(':categoryCode?')} element={<SymptomPage />} />
                        <Route path={AppRoutes.AppointmentNotes()} element={<NotesPage />} />

                        <Route
                          path={AppRoutes.ConfirmRfe()}
                          element={<SecretMobileRoute path='/*' element={<RfeConfirmationPage />} />}
                        />

                        <Route path={AppRoutes.SymptomFollowUp()} element={<SymptomFollowUpPage />} />
                        <Route path={AppRoutes.AcuteAppointmentWarning()} element={<AcuteAppointmentWarningPage />} />

                        <Route path={AppRoutes.Regions()} element={<RegionsPage />} />

                        <Route path={AppRoutes.Appointment()} element={<AppointmentPage />} />

                        <Route path={AppRoutes.AppointmentConfirmation()} element={<AppointmentConfirmationPage />} />

                        <Route path={AppRoutes.InsuranceSelection()} element={<InsuranceSelectionData />} />

                        <Route path={AppRoutes.OutOfReach()} element={<OutOfReachPage />} />

                        <Route path={AppRoutes.UserData()} element={<UserDataPage />} />

                        <Route path={AppRoutes.Authentication()} element={<TwoFaPage />} />

                        <Route path={AppRoutes.AuthenticationConfirm()} element={<TwoFaConfirmationPage />} />

                        <Route path={AppRoutes.AddOnServices()} element={<AddOnServicesPage />} />

                        <Route path={AppRoutes.AnamnesisMandatory()} element={<AnamnesisMandatoryPage />} />
                      </Route>

                      <Route path={'/consent'}>
                        <Route path={AppRoutes.ConsentStart()} element={<ConsentStart />} />
                      </Route>

                      <Route
                        path={AppRoutes.AddAppointmentToCalendar(':appointmentId?')}
                        element={<AddToCalendarPage />}
                      />

                      <Route path={AppRoutes.CancelAppointment(':appointmentId?')} element={<Cancellation />} />

                      <Route path={AppRoutes.VerifyEmail()} element={<AccountVerification />} />

                      <Route path={AppRoutes.ViewMessage(':conversationId?')} element={<ViewMessage />} />

                      <Route
                        path={AppRoutes.Anamnesis()}
                        element={<Anamnesis dismissDownloadBanner={handleDismiss} />}
                      />

                      <Route path={AppRoutes.AnamnesisLanding()} element={<AnamnesisLanding />} />

                      <Route path={AppRoutes.VideoCall(':patientCode')} element={<VideoCall />} />

                      <Route
                        path={AppRoutes.SignupEmailVerificationLanding()}
                        element={<EmailVerificationLandingPage otpType={TOtpTypes.SIGNUP} />}
                      />

                      <Route
                        path={AppRoutes.PasswordResetEmailVerificationLanding()}
                        element={<EmailVerificationLandingPage otpType={TOtpTypes.PASSWORD_RESET} />}
                      />

                      {ENV !== 'PROD' && <Route path={AppRoutes.Debug()} element={<DebugPage />} />}

                      <Route path='*' element={<Navigate to='/booking' replace />} />
                    </SentryRoutes>
                  </Suspense>
                </AppWrapper>
              </BrowserRouter>
            </AppContextProvider>
          </Provider>
        </GlobalErrorBoundary>
      </IntlProvider>
    </HelmetProvider>
  );
}

export default App;
