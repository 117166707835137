import useAppContext from 'AppContext';
import { createMandatoryAnamnesis } from 'services/anamnesis';

import useRemoteConfig from './useRemoteConfig';

export const useAnamnesisMandatory = () => {
  const { context, setContext } = useAppContext();
  const {
    values: { mandatory_anamnesis_enabled },
  } = useRemoteConfig();

  const isAnamnesisMandatoryEnabled = !!mandatory_anamnesis_enabled;
  const isAnamnesisMandatoryCreated = !!context.apptData.anamnesis?.id;
  const uniqueAppId = context.apptData.uniqueAppId;

  const createAnamnesis = async ({ captchaToken }: { captchaToken?: string | null }) => {
    // skip if mandatory anamnesis was already created and stored
    if (isAnamnesisMandatoryCreated) return;

    const anamnesis = await createMandatoryAnamnesis({ captchaToken: captchaToken!, uniqueAppId });
    setContext((prev) => ({ ...prev, apptData: { ...prev.apptData, anamnesis } }));
  };

  return {
    isAnamnesisMandatoryEnabled,
    isAnamnesisMandatoryCreated,
    createAnamnesis,
  };
};
