import iconAnamnesisAccident from 'assets/images/icon-anamnesis-accident.svg';
import iconAnamnesisAdd from 'assets/images/icon-anamnesis-add.svg';
import iconAnamnesisBloodpressure from 'assets/images/icon-anamnesis-blood-pressure.svg';
import iconAnamnesisBlood from 'assets/images/icon-anamnesis-blood.svg';
import iconAnamnesisBowel from 'assets/images/icon-anamnesis-bowel.svg';
import iconAnamnesisCancer from 'assets/images/icon-anamnesis-cancer.svg';
import iconAnamnesisCream from 'assets/images/icon-anamnesis-cream.svg';
import iconAnamnesisDemandingActivities from 'assets/images/icon-anamnesis-demanding-activities.svg';
import iconAnamnesisEveryDayActivities from 'assets/images/icon-anamnesis-everyday-activities.svg';
import iconAnamnesisFitness from 'assets/images/icon-anamnesis-fitness.svg';
import iconAnamnesisFootball from 'assets/images/icon-anamnesis-football.svg';
import iconAnamnesisHeart from 'assets/images/icon-anamnesis-heart.svg';
import iconAnamnesisHomeRemedy from 'assets/images/icon-anamnesis-home-remedy.svg';
import iconAnamnesisHospital from 'assets/images/icon-anamnesis-hospital.svg';
import iconAnamnesisKidney from 'assets/images/icon-anamnesis-kidney.svg';
import iconAnamnesisLiver from 'assets/images/icon-anamnesis-liver.svg';
import iconAnamnesisLungs from 'assets/images/icon-anamnesis-lungs.svg';
import iconAnamnesisMedicine from 'assets/images/icon-anamnesis-medicine.svg';
import iconAnamnesisMeditation from 'assets/images/icon-anamnesis-meditation.svg';
import iconAnamnesisMetabolism from 'assets/images/icon-anamnesis-metabolism.svg';
import iconAnamnesisMovement from 'assets/images/icon-anamnesis-movement.svg';
import iconAnamnesisNatural from 'assets/images/icon-anamnesis-natural.svg';
import iconAnamnesisNervousSystem from 'assets/images/icon-anamnesis-nervous-system.svg';
import iconAnamnesisNoPreexistingCondition from 'assets/images/icon-anamnesis-no-preexisting-condition.svg';
import iconAnamnesisNone from 'assets/images/icon-anamnesis-none.svg';
import iconAnamnesisPhysio from 'assets/images/icon-anamnesis-physio.svg';
import iconAnamnesisPills from 'assets/images/icon-anamnesis-pills.svg';
import iconAnamnesisPreexistingCondition from 'assets/images/icon-anamnesis-preexisting-condition.svg';
import iconAnamnesisRelax from 'assets/images/icon-anamnesis-relax.svg';
import iconAnamnesisSitting from 'assets/images/icon-anamnesis-sitting.svg';
import iconAnamnesisSkeletalSystem from 'assets/images/icon-anamnesis-skeletal-system.svg';
import iconAnamnesisSkin from 'assets/images/icon-anamnesis-skin.svg';
import iconAnamnesisSleep from 'assets/images/icon-anamnesis-sleep.svg';
import iconAnamnesisSmoking from 'assets/images/icon-anamnesis-smoking.svg';
import iconAnamnesisSpecialist from 'assets/images/icon-anamnesis-specialist.svg';
import iconAnamnesisSports from 'assets/images/icon-anamnesis-sports.svg';
import iconAnamnesisStressLevel from 'assets/images/icon-anamnesis-stress-level.svg';
import iconAnamnesisStress from 'assets/images/icon-anamnesis-stress.svg';
import iconAnamnesisSurgery from 'assets/images/icon-anamnesis-surgery.svg';
import iconAnamnesisWalking from 'assets/images/icon-anamnesis-walking.svg';
import iconAnamnesisWeight from 'assets/images/icon-anamnesis-weight.svg';
import iconAnamnesisWorkout from 'assets/images/icon-anamnesis-workout.svg';

export const iconSourceMap: any = {
  heart: iconAnamnesisHeart,
  metabolism: iconAnamnesisMetabolism,
  skeletalSystem: iconAnamnesisSkeletalSystem,
  lungs: iconAnamnesisLungs,
  bowel: iconAnamnesisBowel,
  nervousSystem: iconAnamnesisNervousSystem,
  kidney: iconAnamnesisKidney,
  liver: iconAnamnesisLiver,
  blood: iconAnamnesisBlood,
  cancer: iconAnamnesisCancer,
  skin: iconAnamnesisSkin,
  preexistingCondition: iconAnamnesisPreexistingCondition,
  noPreexistingCondition: iconAnamnesisNoPreexistingCondition,
  everydayActivities: iconAnamnesisEveryDayActivities,
  walking: iconAnamnesisWalking,
  fitness: iconAnamnesisFitness,
  football: iconAnamnesisFootball,
  demandingActivities: iconAnamnesisDemandingActivities,
  meditation: iconAnamnesisMeditation,
  accident: iconAnamnesisAccident,
  add: iconAnamnesisAdd,
  bloodpressure: iconAnamnesisBloodpressure,
  cream: iconAnamnesisCream,
  homeRemedy: iconAnamnesisHomeRemedy,
  hospital: iconAnamnesisHospital,
  medicine: iconAnamnesisMedicine,
  movement: iconAnamnesisMovement,
  natural: iconAnamnesisNatural,
  none: iconAnamnesisNone,
  physio: iconAnamnesisPhysio,
  pills: iconAnamnesisPills,
  relax: iconAnamnesisRelax,
  sitting: iconAnamnesisSitting,
  sleep: iconAnamnesisSleep,
  smoking: iconAnamnesisSmoking,
  specialist: iconAnamnesisSpecialist,
  sports: iconAnamnesisSports,
  stress: iconAnamnesisStress,
  stressLevel: iconAnamnesisStressLevel,
  surgery: iconAnamnesisSurgery,
  weight: iconAnamnesisWeight,
  workout: iconAnamnesisWorkout,
};

export const getInputIcon = (icon: string) => {
  if (!icon) {
    return null;
  }

  return iconSourceMap[icon];
};
