import { TContextType, defaultContext } from 'AppContext';
import dayjs from 'dayjs';

export const sortByFrequency = (arr: any[]) => {
  let frequency: any = {};

  arr.forEach((val) => {
    frequency[val] = 0;
  });

  var uniques = arr.filter((val) => {
    return ++frequency[val] === 1;
  });

  return uniques.sort((a, b) => {
    return frequency[b] - frequency[a];
  });
};

export const unique = (arr: any[]) => {
  return arr.filter((val, i, self) => {
    return self.indexOf(val) === i;
  });
};

export function saveContext(data: any) {
  sessionStorage.setItem('pwa-context', JSON.stringify(data));
}

export function loadContextFromSessionStorage(): TContextType['context'] | undefined {
  const ctx = sessionStorage.getItem('pwa-context');
  let ctxObj;

  if (ctx) {
    ctxObj = JSON.parse(ctx) as TContextType['context'];
    ctxObj.userData.dateOfBirth = ctxObj.userData.dateOfBirth
      ? new Date(ctxObj.userData.dateOfBirth)
      : ctxObj.userData.dateOfBirth;
    ctxObj.apptData.date = ctxObj.apptData.date ? new Date(ctxObj.apptData.date) : ctxObj.apptData.date;
    // If we deploy the context changes, and some patients are already in the flow, they will be presented with the reload modal.
    // This will reload the JS chunks, but the actual consent will be missing.
    // This is to ensure that the patient has consents in context when reloading the screen.
    ctxObj.userData.consent = defaultContext.context.userData.consent;
    ctxObj.apptData.anamnesis = null;
  }

  return ctxObj;
}

/**
 * Calculates the difference in time between two dates
 * Returns the difference in each unit of measure, as well as the actual time difference
 * @param earliestDate - The date to subtract
 * @param latestDate - The date to subtract from
 */
export const getTimeDifference = (earliestDate: Date, latestDate: Date) => {
  let timeDifference = latestDate.getTime() - earliestDate.getTime();
  if (timeDifference < 0) {
    timeDifference = 0;
  }
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return {
    hours,
    minutes,
    seconds,
    timeDifference,
  };
};

/**
 * Returns a locale sensitive date/time string from the given date
 * @param date - The date to format
 */
export const dateToLocaleString = (date: Date) => {
  if (date.getHours() === 0 && date.getMinutes() === 0) return dayjs(date).format('dddd, DD. MMM YYYY');
  else return dayjs(date).format('dddd, DD. MMM YYYY, HH:mm');
};

/**
 * Returns user preferred language
 * ```
 * getLocale() // => 'de'
 * ```
 * Supported languages EN and DE
 */
export const getLocale = () => {
  return localStorage.getItem('locale') || (navigator.language.includes('de') ? 'de' : 'en');
};

export function getShortenedString(text: string, length: number) {
  return text.length <= length ? text : `${text.slice(0, length)}...`;
}

export const isAndroid = () => {
  return navigator.userAgent.match(/Android/i);
};

export const isIOS = () => {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

export const isChromeIOS = () => {
  return navigator.userAgent.match(/CriOS/);
};

export const formatPhoneNumber = (phone: string) => {
  if (!phone) return '';

  if (!phone.startsWith('+49')) return phone;

  // format German numbers
  return `(0${phone.substr(3, 2)}) ${phone.substr(5, 4)} ${phone.substr(9)}`;
};
